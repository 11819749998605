<template>
  <div class="container-xl">
    <div class="card col-lg-12">
      <div class="card-header justify-content-between">
        <div class="card-title">
          <div class="flex flex-row mb-2 pr-2 float-right">
            <div class="flex flex-row mb-2 pr-2 float-right">
              <b-input style="width: 200px" size="sm" type="text" v-model="user.search_keyword" placeholder="Input search keyword" class="mr-2"/>
              <el-select size="small" v-model="user.search_by" class="mr-2" style="width: 150px">
                <el-option v-for="(item, index) in user.search_options" :key="index" :value="item.value" :label="item.text"/>
              </el-select>
              <el-select size="small" v-model="user.searchType" class="mr-2" style="width: 150px">
                <el-option v-for="(item, index) in user.searchUserTypeOptions" :key="index" :value="item.value" :label="item.text"/>
              </el-select>
              <el-button type="primary" @click="searchHandlerGami" size="small"><font-awesome-icon icon="search"/> Search</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
               <th width="20%" @click="toggleSort('full_name')" style="cursor: pointer"> Name
                 <span v-if="isSorted && sortKey === 'full_name'">
                  {{ sortOrder === 1 ? '🔼' : '🔽' }}
                </span>
              </th>
              <th width="20%">Email</th>
              <th width="10%">Phone Number</th>
              <th>Company Name</th>
              <th>Status </th>
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="item in sortedList" :key="item.id">
              <td>{{ item.full_name }}</td>
              <td>{{ item.email }}</td>
              <td class="text-nowrap text-muted">{{ item.phone_number }}</td>
              <td>{{ item.company_name || '-' }}</td>
              <td>
                <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                  <a class="dropdown-item btn-sm cursor-pointer" @click="viewDetail(item)"><font-awesome-icon icon="ticket-alt" class="text-primary"/>&nbsp; Detail</a>
                </div>
              </td>
             </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFromApp }} to {{ showingUntilApp }} of {{ total_rows }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="size"
            :limit="5"
            :total-rows="total_rows"
            v-model="page"
          />
        </div>
      </div>
      <b-modal size="lg" v-model="isShownModalCreateEvent" title="Create Event" hide-footer @hide="onHide">
        <b-form @submit.prevent="onSubmit" @reset="onHide" v-loading="loading.detail">
          <b-form-group label="Name">
            <b-input v-model="form.name" placeholder="Input Event Name" type="text" />
          </b-form-group>
          <b-form-group label="Title">
            <b-input v-model="form.title" placeholder="Input title" type="text" />
          </b-form-group>
          <b-form-group label="Description">
            <b-input v-model="form.description" placeholder="Input Event Name" type="text" />
          </b-form-group>
          <b-form-group label="Type">
            <el-select  class="mb-2"  v-model="form.type" placeholder="Select Type" style="width: 100%">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="User Type">
            <el-select class="mb-2" @change="handleCompanyType" v-model="form.user_type" placeholder="Select User Type" style="width: 100%">
              <el-option v-for="item in userTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Company"  v-if="form.user_type === 'private'">
            <el-select multiple class="mb-2" :loading="loading.company" v-model="form.companies" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
              <el-option v-for="item in companies.rows" :key="item.company_id" :label="item.company_name" :value="item.company_id"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Exclude Company"  v-if="form.user_type === 'private'">
            <el-select multiple class="mb-2" :loading="loading.company" v-model="form.companies_excluded" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
              <el-option v-for="item in companies.rows" :key="item.company_id" :label="item.company_name" :value="item.company_id"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Start Date">
            <el-date-picker :picker-options="datePickerStartOptions" class="w-full" v-model="form.start_date" type="date" placeholder="Pick start date" format="dd MMMM yyyy" value-format="yyyy-MM-dd"  @input="updateEndDatePickerOptions" />
          </b-form-group>
          <b-form-group label="End Date">
            <el-date-picker :picker-options="datePickerEndOptions" :key="endDateKey" class="w-full" v-model="form.end_date" type="date" placeholder="Pick end date" format="dd MMMM yyyy" value-format="yyyy-MM-dd"/>
          </b-form-group>
          <!-- <b-form-group label="Status">
            <el-select  class="mb-2"  v-model="form.status" placeholder="Select User Type" style="width: 100%">
              <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </b-form-group> -->
          <b-form-group label="Max Claim Days">
            <b-input v-model="form.max_claim_days" @change="adjustRewardsConfig"  placeholder="Input max_claim_days" type="number"/>
          </b-form-group>
          <b-form-group label="Reward Config" v-if="form.type === 'transaction_reward'">
            <b-row v-for="(item, index) in rewards_config" :key="index" class="mb-3" align-items="center">  
              <b-col cols="">
                <b-input type="text" muted v-model="item.product" />
              </b-col>
              <b-col v-if="item" cols="4">
                <b-input type="number" v-model="item.coins" :placeholder="'Input coins day ' + (index + 1)"/>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label="Reward Config" v-if="form.type === 'daily_login'">
            <b-row v-for="(item, index) in rewards_config_daily_login" :key="index" class="mb-3" align-items="center">  
              <b-col cols="">
                <b-input type="text"  :disabled="true" v-model="item.placeholder"/>
              </b-col>
              <b-col cols="">
                <b-input type="text" :disabled="true" v-model="item.day" />
              </b-col>
              <b-col v-if="item" cols="8">
                <b-input type="number" v-model="item.coins" :placeholder="'Input coins day ' + (index + 1)"/>
              </b-col>
            </b-row>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="onSubmit" :disabled="!isFormValid" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
      <b-modal size="lg" v-model="isShownModalUpdateEvent" title="Detail" hide-footer @hide="onHide">
        <b-form @submit.prevent="doUpdateEvent" @reset="onHide" v-loading="loading.detail">
          <b-form-group label="Name">
            <b-input v-model="form.name" placeholder="Input Event Name" type="text" />
          </b-form-group>
           <b-form-group label="Title">
            <b-input v-model="form.title" placeholder="Input Event Title" type="text" />
          </b-form-group>
          <b-form-group label="Description">
            <b-input v-model="form.description" placeholder="Input Event Name" type="text"  />
          </b-form-group>
          <b-form-group label="Type">
            <el-select  class="mb-2"  v-model="form.type" placeholder="Select Type" style="width: 100%">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="User Type">
            <el-select  class="mb-2" @change="handleCompanyType" v-model="form.user_type" placeholder="Select User Type" style="width: 100%">
              <el-option v-for="item in userTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Company"  v-if="form.user_type === 'private'">
            <el-select multiple class="mb-2" :loading="loading.company" v-model="form.companies" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
              <el-option v-for="item in companies.rows" :key="item.company_id" :label="item.company_name" :value="item.company_id"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Exclude Company"  v-if="form.user_type === 'private'">
            <el-select multiple class="mb-2" :loading="loading.company" v-model="form.companies_excluded" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
              <el-option v-for="item in companies.rows" :key="item.company_id" :label="item.company_name" :value="item.company_id"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Start Date">
            <el-date-picker @input="updateEndDatePickerOptions" :picker-options="datePickerStartOptions" class="w-full" v-model="form.start_date" type="date" placeholder="Pick start date" format="dd MMMM yyyy" value-format="yyyy-MM-dd"/>
          </b-form-group>
          <b-form-group label="End Date">
            <el-date-picker :key="endDateKey" :picker-options="datePickerEndOptions" class="w-full" v-model="form.end_date" type="date" placeholder="Pick end date" format="dd MMMM yyyy" value-format="yyyy-MM-dd"/>
          </b-form-group>
          <!-- <b-form-group label="Status">
            <el-select  class="mb-2"  v-model="form.status" placeholder="Select User Type" style="width: 100%">
              <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </b-form-group> -->
           <b-form-group label="Max Claim Days">
            <b-input v-model="form.max_claim_days"  @change="adjustRewardsConfigUpdate" placeholder="Input max_claim_days" type="number"/>
          </b-form-group>
          <b-form-group label="Reward Config" v-if="form.type === 'transaction_reward'">
            <b-row v-for="(item, index) in form.rewards_config" :key="index" class="mb-3" align-items="center">  
              <b-col cols="">
                <b-input type="text" muted v-model="item.product" />
              </b-col>
              <b-col v-if="item" cols="4">
                <b-input type="number" v-model="item.coins" :placeholder="'Input coins day ' + (index + 1)"/>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label="Reward Config" v-if="form.type === 'daily_login'">
            <b-row v-for="(item, index) in form.rewards_config" :key="index" class="mb-3" align-items="center">  
              <b-col cols="">
                <b-input type="text" :disabled="true" v-model="item.day" />
              </b-col>
              <b-col v-if="item" cols="8">
                <b-input type="number" v-model="item.coins" :placeholder="'Input coins day ' + (index + 1)"/>
              </b-col>
            </b-row>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="doUpdateEvent" :disabled="!isFormValid" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
        <b-modal size="xl" v-model="showDetailGamification" :title="'Detail Transaction ' + selected_user.owned_by" hide-footer>
        <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="20%">ID</th>
              <th width="20%">Event Name</th>
              <th>User Type</th>
              <th>Coin Claimed </th>
              <th>Max Claim </th>
              <th>Date </th>
              <th>Status </th>
              <th># </th>
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="item in list" :key="item._id">
              <td> {{item._id || "-"}} </td>
              <td> {{item.event_id?.name || "-"}} </td>
              <!-- <td> {{item.participants_id}} </td> -->
              <td> {{item.event_id?.user_type || "-"}} </td>
              <td> {{item.coin || "-"}} </td>
              <td> {{item.event_id?.event_config.max_claim_daily || "-"}} </td>
              <td> {{item.date_str || "-"}} </td>
              <td><span class="tag" :class="item.event_status_color">{{ item.event_status_str || "-"}}</span></td>
              <td class="text-nowrap text-muted child1-sticky">
                <button @click="viewDetailCoin(item)" title="View Detail" type="button"
                  class="btn btn-icon btn-flickr btn-sm"><font-awesome-icon icon="eye" /></button>
              </td>
             </tr>
            <tr>
              <td colspan="13">
                <b-collapse id="detail-coin" v-model="collapseOpenedCoin">
                  <b-card><br>
                    <div v-if="loadingProductStatus" class="d-flex justify-content-center align-items-center" style="height: 200px;">
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <p> ID: {{ selected_coin._id }}</p>
                      <div class="table-responsive">
                        <table class="table table-vcenter w-full" v-if="selected_coin.type === 'transaction_reward'">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Coin</th>
                              <th>Order ID</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in selected_coin.details" :key="item._id">
                              <td>{{ item.product }}</td>
                              <td>{{ item.coins }}</td>
                              <td>{{ item.order_id }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="table table-vcenter w-full" v-if="selected_coin.type === 'daily_login' " >
                          <thead>
                            <tr>
                              <th>Day</th>
                              <th>Date</th>
                              <th>Amount</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in selected_coin.details" :key="item._id">
                              <td>{{ item.day }}</td>
                              <td> {{ formatDateTime(item.date_str) }} </td>
                              <td>{{ item.amount }}</td>
                              <td>
                                <span class="tag" :class="tx_status[item.status].color">{{ tx_status[item.status].text }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_row == 0" :image="emptyStateImage"></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_row }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandlerGami"
            :per-page="size"
            :limit="5"
            :total-rows="total_row"
            v-model="page"
          />
        </div>
      </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import { GET_TRANSACTION_GAMIFICATION  } from '@/store/modules/gamification';
import moment from 'moment';
import { GET_COMPANIES } from '@/store/modules/companies';
import { GET_APPUSERS} from '@/store/modules/app_user';
export default {
  metaInfo: {
    title: 'Gamification',
  },
  data() {
    return {
      selected_user: {},
      search_keyword: '',
      search_by: 'full_name',
      search: '',
      user_filter: {
        search_by: 'full_name',
        search_keyword: '',
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
      },
      collapseOpenedCoin: false,
      search_user: '',
      sort_type: 'desc',
      sort_by: 'status',
      companies: {
        rows: [],
        count: 0,
      },
      list_user: [],
      sortKey: 'full_name', 
      sortOrder: 1 ,
      isSorted: true,
      user: {
        search_by: 'full_name',
        searchType: 1,
        search_keyword: '',
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
        searchUserTypeOptions: [
          { value: 1, text: 'Private' },
          { value: 0, text: 'Public' },
        ],
      },
      transaction_gamifications: {
        data: [],
        count: 0,
      },
      datePickerStartOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'), 
      },
      datePickerEndOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'), 
      },
      endDateKey: 0, 
      form: {
        name: '',
        title: '',
        // description: '',
        companies: [],
        companies_excluded: [],
        description: '',
        type: '',
        user_type: '' ,
        start_at: moment().format('YYYY-MM-DD'),
        end_at: moment().add(7, 'days').format('YYYY-MM-DD'),
        status: [] ,
        max_claim_days: 0,
        rewards_config: [
          { product: 'Voucher', coins: null }, 
          { product: 'Pulsa', coins: null }, 
          { product: 'Token PLN', coins: null },
          { product: 'Paket Data', coins: null },
          { product: 'Tagihan Listrik', coins: null }, 
          { product: 'Tagihan Telepon', coins: null }, 
          { product: 'Internet & TV Kabel', coins: null }, 
          { product: 'PDAM', coins: null },
        ]
      },
      rewards_config: [
        { product: 'Voucher', coins: null }, 
        { product: 'Pulsa', coins: null }, 
        { product: 'Token PLN', coins: null },
        { product: 'Paket Data', coins: null },
        { product: 'Tagihan Listrik', coins: null }, 
        { product: 'Tagihan Telepon', coins: null }, 
        { product: 'Internet & TV Kabel', coins: null }, 
        { product: 'PDAM', coins: null },
      ],
      rewards_config_daily_login: [
        { day: 1, coins: null, placeholder: "Day" }, 
        { day: 2, coins: null, placeholder: "Day" }, 
        { day: 3, coins: null, placeholder: "Day" },
        { day: 4, coins: null, placeholder: "Day" }, 
        { day: 5, coins: null, placeholder: "Day" }, 
        { day: 6, coins: null, placeholder: "Day" }, 
        { day: 7, coins: null, placeholder: "Day" },
      ],
      tx_status: {
        0: {
          text: 'Unclaimed',
          color: 'tag-default',
        },
        1: {
          text: 'Ready',
          color: 'tag-default',
        },
        2: {
          text: 'Claimed',
          color: 'tag-success',
        },
        3: {
          text: 'Skipped',
          color: 'tag-warning',
        },
      },
      emptyStateImage,
      isShownModalUpdateEvent: false,
      isShownModalUpdateStatus: false,
      loading: {
        modal_content: false,
        table: false,
      },
      data_event_update: [],
      page: 1,
      per_page: 20,
      size: 10,
      list: [],
      selected_coin: {
        details: {},
      },
      list_user: [],
      total_rows: 0,
      total_row: 0,
      isShownModalCreateEvent: false,
      showDetailGamification: false,
      loadingProductStatus: false,
      loading: {
        detail: false,
        submit: false,
        company: false,
        table: false
      },
      typeOptions: [
        {
          value: 'daily_login',
          label: 'Daily Login',
        }, 
        {
          value: 'transaction_reward',
          label: 'Transaction Reward',
        },
      ],
      userTypeOptions: [
        {
          value: 1,
          label: 'Private',
        }, 
        {
          value: 0,
          label: 'Public',
        },
        //  {
        //   value: '',
        //   label: 'All',
        // },
      ],
      statusOptions: [
        {
          value: 'submitted',
          label: 'Submitted',
        },
        {
          value: 'draft',
          label: 'Draft',
        },
        {
          value: 'publish',
          label: 'Publish',
        },
      ],
      updateStatusOptions: [
        {
          value: 'hold',
          label: 'Hold',
        },
        {
          value: 'draft',
          label: 'Draft',
        },
        {
          value: 'publish',
          label: 'Publish',
        },
      ],
    };
  },
  mounted() {
    this.getListUser();
    this.adjustRewardsConfig();
    this.adjustRewardsConfigUpdate();
  },
  computed: {
    showingFrom() {
      return this.total_row ? ((this.page - 1) * this.size) + 1 : 0;
    },
    showingUntil() {
      if (this.total_row) {
        if (this.list.length < this.size) {
          return this.total_row;
        }
        return this.page * this.size;
      }
      return 0;
    },
    showingFromApp() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntilApp() {
      if (this.total_rows) {
        if (this.list_user.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    isFormValid() {
      return this.form.name && this.form.title && this.form.description &&
             this.form.type && this.form.user_type &&
             this.form.start_date && this.form.end_date
    },
    sortedList() {
      let sorted = this.list_user.slice();
      if (this.isSorted && this.sortKey) {
        sorted.sort((a, b) => {
          let result = 0;
          if (a[this.sortKey] && b[this.sortKey]) {
            const valA = a[this.sortKey];
            const valB = b[this.sortKey];
            if (typeof valA === 'string' && typeof valB === 'string') {
              result = valA.localeCompare(valB);
            } else {
              result = valA < valB ? -1 : valA > valB ? 1 : 0; 
            }
          }
          return result * this.sortOrder;
        });
      }
      return sorted;
    }
  },
  methods: {
    viewDetailCoin(item) {
       this.selected_coin = item;
      //  console.log("this.selected_coin", this.selected_coin)
      if (!this.collapseOpenedCoin) {
        this.collapseOpenedCoin = true;
      } else {
        this.collapseOpenedCoin = false;
      }
    },
    formatDateTime(dateString) {
      return moment(dateString).format('DD MMM YYYY, HH:mm');
    },
    searchCompany(value) {
      clearTimeout(this.timeoutSearchCompany);
      this.timeoutSearchCompany = setTimeout(() => {
        this.loadCompanies({
          search_keyword: value,
        });
      }, 1000);
    },
    async loadCompanies({ search_keyword = '' } = {}) {
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.companies = this.$store.getters.companies;
    },
    closeDetailGamification() {
      this.showDetailGamification = false
    },
    async getListUser() {
      this.isLoading = true;
      await this.$store.dispatch(GET_APPUSERS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        is_private: this.user.searchType,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.app_users;
      this.total_rows = count || 0;
      this.list_user = rows.map((v) => {
        v.birth_place_date = (v.birth_place && v.birthday) ? [v.birth_place, moment(v.birthday).format('DD MMM YYYY')].join(', ') : '-';
        return v;
      });
      this.isLoading = false;
    },
    async viewDetail(item) {
      try {
        this.selected_user = item;
        // console.log("selected_user", this.selected_user);
        await this.$store.dispatch(GET_TRANSACTION_GAMIFICATION, {
          owned_by: item.owned_by,
        });
        this.showDetailGamification = true;
        const transactionData = this.$store.getters.transaction_gamifications?.data || [];
         const transactionCount = this.$store.getters.transaction_gamifications?.count || 0;
        this.list = transactionData;
        this.total_row = transactionCount;

      } catch (error) {
        const errorMessage = error.response?.data?.error?.title || 'Something went wrong';
        this.$message({
          title: 'Failed',
          type: 'error',
          message: errorMessage,
        });
      }
    },
    async handleCompanyChange() {
      this.loadCompanies();
    },
    handleCompanyType(value) {
      if (value === 'private') {
        this.form.company_id = [];
        this.loadCompanies();
      }
    },
    updateEndDatePickerOptions() {
      if (this.form.start_date) {
        const selectedStartDate = moment(this.form.start_date, 'YYYY-MM-DD');
        this.datePickerEndOptions = {
          disabledDate: (date) => {
            const selectedDate = moment(date);
            const isDisabled = selectedDate.isBefore(moment(), 'days') || selectedDate.isBefore(selectedStartDate, 'day');
            return isDisabled;
          },
        };
        this.endDateKey += 1;
      }
    },
    adjustRewardsConfi() {
      let maxDays = parseInt(this.form.max_claim_days) || 0;
      if (this.form.type === 'transaction_reward') {
        maxDays = Math.min(maxDays, 7);
      }
      this.rewards_config_daily_login = [];
      for (let i = 1; i <= maxDays; i++) {
        this.rewards_config_daily_login.push({
          day: i,
          coins: null,
          placeholder: `Day ${i}`,
        });
      }
      if (this.form.type === 'transaction_reward') {
        this.form.rewards_config = [
          { product: 'Voucher', coins: null }, 
          { product: 'Pulsa', coins: null }, 
          { product: 'Token PLN', coins: null },
          { product: 'Paket Data', coins: null },
          { product: 'Tagihan Listrik', coins: null }, 
          { product: 'Tagihan Telepon', coins: null }, 
          { product: 'Internet & TV Kabel', coins: null }, 
          { product: 'PDAM', coins: null },
        ];
      }
    },
    adjustRewardsConfig() {
      let maxDays = parseInt(this.form.max_claim_days) || 0;
      if (!this.form.rewards_config) {
        this.form.rewards_config = [];
      }
      this.form.rewards_config = [];
      const defaultRewardsConfig = [
          { product: 'Voucher', coins: null }, 
          { product: 'Pulsa', coins: null }, 
          { product: 'Token PLN', coins: null },
          { product: 'Paket Data', coins: null },
          { product: 'Tagihan Listrik', coins: null }, 
          { product: 'Tagihan Telepon', coins: null }, 
          { product: 'Internet & TV Kabel', coins: null }, 
          { product: 'PDAM', coins: null },
      ];
      if (maxDays <= 7) {
        this.form.rewards_config = [...defaultRewardsConfig];
      } else {
        this.form.rewards_config = [...defaultRewardsConfig];
        this.form.rewards_config.push({ product: '', coins: null }); 
      }
      this.rewards_config_daily_login = [];
      for (let i = 1; i <= maxDays; i++) {
        this.rewards_config_daily_login.push({
          day: i,
          coins: null,
          placeholder: `Day ${i}`,
        });
      }
    },
    adjustRewardsConfigUpdate() {
      const maxDays = parseInt(this.form.max_claim_days) || 0;
      const currentLength = this.form.rewards_config.length;
      if (maxDays > currentLength) {
        for (let i = currentLength; i < maxDays; i++) {
          this.form.rewards_config.push({
            day: i + 1, 
            coins: null 
          });
        }
      }
      if (maxDays < currentLength) {
        this.form.rewards_config = this.form.rewards_config.slice(0, maxDays);
      }
    },
    searchHandler() {
      this.page = 1;
      this.search_by = this.user_filter.search_by;
      this.search_keyword = this.user_filter.search_keyword;
      this.getList();
    },
    searchHandlerGami() {
      this.search_by = this.user.search_by;
      this.search_keyword = this.user.search_keyword;
      this.searchType = this.user.searchType
      this.getListUser();
    },
    // new
    toggleSort(key) {
      if (this.sortKey === key) {
        this.sortOrder *= -1; 
      } else {
        this.sortKey = key;  
        this.sortOrder = 1;    
      }
      this.isSorted = true;   
    },
    resetSort() {
      this.isSorted = false;  
      this.sortKey = null;
      this.sortOrder = 1;
    },
    handlerBtnNewEvent() {
      this.isShownModalCreateEvent = true;
    },
    async handlerBtnUpdateEvent(item) {
      this.isShownModalUpdateEvent = true;
      this.selected_transaction = item;
      this.loading.table = true;
      try {
        await this.$store.dispatch(GET_BY_ID_GAMIFICATION, {
          id: this.selected_transaction._id,
        });
        const by_id_gamifications = this.$store.getters.by_id_gamifications;
        if (by_id_gamifications && by_id_gamifications.data) {
          this.form = by_id_gamifications.data || [];
        }
      } catch (err) {
        console.error("Error fetching gamification ID: ", err);
      } finally {
        this.loading.table = false;
        this.getList();
      }
    },
    async handlerBtnUpdateStatus(item) {
      this.isShownModalUpdateStatus = true;
      this.selected_transaction = item;
      this.loading.table = true;
      try {
        await this.$store.dispatch(GET_BY_ID_GAMIFICATION, {
          id: this.selected_transaction._id,
        });
        const by_id_gamifications = this.$store.getters.by_id_gamifications;
        if (by_id_gamifications && by_id_gamifications.data) {
          this.form = by_id_gamifications.data || [];
        }
      } catch (err) {
        console.error("Error fetching gamification ID: ", err);
      } finally {
        this.loading.table = false;
        this.getList();
      }
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getListUser();
    },
    pageChangeHandlerGami(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.loading.table = true;
      try {
        await this.$store.dispatch(GET_TRANSACTION_GAMIFICATION, {
        });
        this.transaction_gamifications = this.$store.getters.transaction_gamifications;
      } catch (err) {
        console.error("Error fetching gamification transaction: ", err);
      } finally {
        this.loading.table = false;
      }
    },
    async onSubmit() {
      try {
        const rewardsConfigData = this.form.type === 'transaction_reward'
          ? this.form.rewards_config.map(item => ({
              product: item.product,
              coins: parseInt(item.coins) || 0,
            }))
          : this.rewards_config_daily_login.map(item => ({
              day: item.day,  
              coins: parseInt(item.coins) || 0,
            }));
        const requestBody = {
          name: this.form.name,
          title: this.form.title,
          description: this.form.description,
          companies: this.form.companies,
          companies_excluded: this.form.companies_excluded,
          type: this.form.type,
          user_type: this.form.user_type,
          start_date: this.form.start_at,
          end_date: this.form.end_at,
          max_claim_days: parseInt(this.form.max_claim_days),
          rewards_config: rewardsConfigData 
        }; 
        const response = await this.$store.dispatch(CREATE_EVENT_GAMIFICATION, { data: requestBody });      
        this.data_event = response;
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Event successfully created',
        });
        this.clearForm();
        this.onHide();
        this.getList();
      } catch (err) {
        popupErrorMessages(err.response.data);
      }
    },
    async doUpdateEvent() {
      this.$confirm(
        'You will update the Event. Make sure you have input the data correctly. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
              const rewardsConfigWithIntegers = this.form.rewards_config.map(item => ({
                ...item,
                coins: parseInt(item.coins)
              }));
              const requestBody = {
                name: this.form.name, 
                title: this.form.title, 
                description: this.form.description,
                companies: this.form.companies,
                companies_excluded: this.form.companies_excluded,
                type: this.form.type,
                user_type: this.form.user_type,
                start_date: this.form.start_date,
                end_date: this.form.end_date,
                max_claim_days: parseInt(this.form.max_claim_days),
                rewards_config: rewardsConfigWithIntegers 
              };
              const id = this.form._id;
              await this.$store.dispatch(UPDATE_EVENT_GAMIFICATION, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'Event updated',
                  });
                  this.onHide();
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async doUpdateStatus() {
      this.$confirm(
        'You will update the status. Make sure you have input the data correctly. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
              const requestBody = {
                status: this.form.status, 
              };
              const id = this.form._id;
              await this.$store.dispatch(UPDATE_STATUS_EVENT_GAMIFICATION, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'Status updated',
                  });
                  this.onHide();
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async handlerBtnDelete(item) {
      this.selected_transaction = item
      this.$confirm(
        'You will delete the Event. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
              const requestBody = {
                status: this.selected_transaction.status, 
              };
              const id = this.selected_transaction._id;
              await this.$store.dispatch(DELETE_EVENT_GAMIFICATION, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'Event deleted',
                  });
                  this.onHide();
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    onHide() {
      this.isShownModalCreateEvent = false;
      this.isShownModalUpdateEvent = false;
      this.isShownModalUpdateStatus = false;
      this.clearForm();
    },
    clearForm() {
      this.form.name= '';
      this.form.title = '';
      this.form.description = '';
      this.form.companies = '';
      this.form.companies_excluded ='',
      this.form.status = '';
      this.form.user_type = '';
      this.form.type= '';
      this.form.start_date = '';
      this.form.end_date = '';
      this.form.max_claim_days = '';
      this.form.rewards_config = [
        { product: 'Voucher', coins: null }, 
        { product: 'Pulsa', coins: null }, 
        { product: 'Token PLN', coins: null },
        { product: 'Tagihan Listrik', coins: null }, 
        { product: 'Tagihan Telepon', coins: null }, 
        { product: 'Internet & TV Kabel', coins: null }, 
        { product: 'PDAM', coins: null },
      ];
    },
  },
};
</script>